.shade {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(16, 16, 16, 0.93) 90%, rgba(16, 16, 16, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(16, 16, 16, 0.93) 90%, rgba(16, 16, 16, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ed000000', GradientType=0);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0 0 15px;
}

@media screen and (max-width: 960px) {
  .shade {
    height: 30px;
    padding: 0 0 18px;
  }
}

.title {
  margin: 20px 5% auto;
}

.title p {
  margin-top: 10px;
  font-size: 28px;
  color: #fff;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  line-height: 1.357em;
  font-style: italic;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-break: anywhere;
}

@media screen and (max-width: 960px) {
  .title p {
    font-size: 20px;
    line-height: 1.4em;
  }
}
